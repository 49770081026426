import {
  EMainPageEvents,
  type IMainPageBattleBannerClickedEvent,
  type IMainPageFreeCasesBannerClickedEvent,
  type IMainPageRewardClaimedEvent,
  type IMainPageSecretShopOpenedEvent,
  type IMainPageTaskCompletedEvent,
} from '../types/mainPage.types';

export class MainPageEvents {
  static battleBannerClicked(event: IMainPageBattleBannerClickedEvent) {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EMainPageEvents.BATTLE_BANNER_CLICKED,
      eventProperties: event,
    });
  }

  static freeCasesBannerClicked(event: IMainPageFreeCasesBannerClickedEvent) {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EMainPageEvents.FREE_CASES_BANNER_CLICKED,
      eventProperties: event,
    });
  }

  static freeCasesClicked() {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EMainPageEvents.FREE_CASES_CLICKED,
    });
  }

  static freeCasesTabClicked() {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EMainPageEvents.FREE_CASES_TAB_CLICKED,
    });
  }

  static giveawayTabClicked() {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EMainPageEvents.GIVEAWAY_TAB_CLICKED,
    });
  }

  static infoPopupClicked() {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EMainPageEvents.INFO_POPUP_CLICKED,
    });
  }

  static newCasesTabClicked() {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EMainPageEvents.NEW_CASES_TAB_CLICKED,
    });
  }

  static rewardClaimed(event: IMainPageRewardClaimedEvent) {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EMainPageEvents.REWARD_CLAIMED,
      eventProperties: event,
    });
  }

  static secretShopOpened(event: IMainPageSecretShopOpenedEvent) {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EMainPageEvents.SECRET_SHOP_OPENED,
      eventProperties: event,
    });
  }

  static smallEventClicked() {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EMainPageEvents.SMALL_EVENT_CLICKED,
    });
  }

  static taskCompleted(event: IMainPageTaskCompletedEvent) {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EMainPageEvents.TASK_COMPLETED,
      eventProperties: event,
    });
  }

  static topPlayersTabClicked() {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EMainPageEvents.TOP_PLAYERS_TAB_CLICKED,
    });
  }
}
