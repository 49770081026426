/* Состояния челенджа */
export enum EBattlepassLevelState {
  CURRENT = 'current',
  DONE = 'done',
  LOCKED = 'locked',
  RECEIVED = 'received',
}

/* Типы челенджа */
export enum EBattlepassLevelType {
  CLASSIC = 'classic',
  RARE = 'rare',
  ULTRA = 'ultra',
}

/* Объект награды баланса для челенджей */
export interface IBattlepassBalance {
  coins?: number;
  money?: number;
}

/* Объект кейса для челенджей */
export interface IBattlepassCase {
  amount: number;
  img: string;
  link: string;
  name: string;
}

/* Объект уровня челенджей */
export interface IBattlepassLevel {
  balanceData?: IBattlepassBalance;
  caseData?: IBattlepassCase;
  cost: number;
  costDiff: number;
  level: number;
  state: EBattlepassLevelState;
  type: EBattlepassLevelType;
}

/* Объект награды за доп.задания */
export interface IBattlepassTaskReward extends IBattlepassBalance {
  case?: IBattlepassCase;
}

/* Объект дополнительного описания для доп.задания */
export interface IBattlepassTaskObjective {
  link: string;
  text: string;
}

/* Объект прогресса в челендже и доп.задании */
export interface IBattlepassProgress {
  current: number;
  end: number;
}

/* Объект доп.задания челенджа */
export interface IBattlepassTask {
  description: string;
  id: number;
  level: number;
  objectiveData?: IBattlepassTaskObjective;
  progressData?: IBattlepassProgress;
  reward: IBattlepassTaskReward;
  taskLink: string;
}

/* Объект иконок челенджей */
export interface IBattlepassIcons {
  case: string;
  coin: string;
  rare: string;
  ultra: string;
}
